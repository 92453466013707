import styles from "./Login1.module.css";

const Login1 = () => {
  return (
    <div className={styles.login1}>
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
      <img className={styles.vectorIcon1} alt="" src="/vector1.svg" />
      <img className={styles.frameIcon} alt="" src="/frame.svg" />
      <div className={styles.frame}>
        <b className={styles.logIn}>Log in</b>
      </div>
      <div className={styles.frame1}>
        <div className={styles.logIn}>Username</div>
      </div>
      <div className={styles.frame2}>
        <div className={styles.logIn}>Password</div>
      </div>
      <img className={styles.frameIcon1} alt="" src="/frame1.svg" />
      <img className={styles.frameIcon2} alt="" src="/frame2.svg" />
      <button className={styles.frame3}>
        <button className={styles.frame4}>
          <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
          <div className={styles.logIn1}>Log in</div>
        </button>
      </button>
      <div className={styles.frame5}>
        <div className={styles.maskGroup}>
          <div className={styles.demo}>DEMO</div>
        </div>
      </div>
      <div className={styles.frame6}>
        <b className={styles.logIn}>CardLocate</b>
      </div>
    </div>
  );
};

export default Login1;
